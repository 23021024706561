












































































































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HelpFunc extends Vue {
  show = true
}
