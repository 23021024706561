












































import { Component, Vue } from 'vue-property-decorator'
import HelpItemLogin from '@/components/info/HelpItemLogin.vue'
import HelpItemDevice from '@/components/info/HelpItemDevice.vue'
import HelpItemFunc from '@/components/info/HelpItemFunc.vue'
import HelpItemEtas from '@/components/info/HelpItemEtas.vue'
import HelpItemSetting from '@/components/info/HelpItemSetting.vue'

@Component({ components: { HelpItemLogin, HelpItemDevice, HelpItemFunc, HelpItemEtas, HelpItemSetting } })
export default class HelpIndex extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }

  get visibleClose (): boolean {
    return this.$route.path === '/help'
  }
}
